@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;

$brand-orange: #ff8521;
$brand-black: #000000;

.step5 {
  table {
    text-align: left;

    td {
      font-family: $font-family;
      border-top: 0px;
    }
  }
  .block-orange {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .riskscore {
    font-size: 130px;
    font-weight: bold;
    letter-spacing: -10px;
  }

  .riskscore-description {
    text-align: left;

    p:nth-child(2) {
      font-size: 20px;

      span {
        color: $brand-orange;
      }
    }
  }

  .MuiButton-root {
    background-color: $brand-black !important;
    color: white;
    width: 200px;
    height: 50px;
  }

  .MuiButton-root:hover {
    background-color: $brand-black !important;
  }
}
