@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
$font-family: "Roboto", sans-serif;

body {
    font-family: $font-family;
    background-color: white !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    a {
        text-decoration: none;
    }
    table {
        border-collapse: collapse;
    }
    & #main-content {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        text-align: center;
        & .body-content {
                flex: 1;
                min-height: 97vh;
            & .stepsize {
                margin: auto
            }
        }
    }

}