.switcher-active-item {
  text-decoration: none;
  cursor: default;
  color: #262b3e !important;
}

.switcher-active-item,
.switcher-active-item:hover {
  font-weight: bold !important;
}
.apexcharts-canvas {
  margin: "auto";
  margin-top: "20px";
}
#chart-wrap {
  max-width: 800px;
  position: relative;
  margin:auto;
  z-index: 9;
}
.legend {
  max-width: 800px;
  right: 40px;
  top: 10px;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  display: flex;
  height: 40px;
  label{
    padding: 5px;
  }
  .MuiToggleButton-root.Mui-selected{
    font-weight: bold;
  }
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #ECEFF1;
  border-bottom: 1px solid #ddd;
}

.apexcharts-tooltip-title {
  padding: 6px;
  font-size: 12px;
  margin-bottom: 4px;
}
.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
  padding-bottom: 4px;
}
.apexcharts-text tspan{
  font-size: 12px;
  font-family: "Roboto", sans-serif !important;
}
.apexcharts-title-text {
  font-family: "Roboto", sans-serif !important;
}