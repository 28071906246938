@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

body {
  font-family: $font-family;
  background-color: white !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
// .Toastify__toast-body {
//   color: black;
//   font-size: 1rem;
// }

// svg.MuiCircularProgress-svg {
//   color: #26a64a;
// }

// .logo {
//   position: relative;
//   display: inline-block;
//   margin: auto;
//   .img-top {
//     display: none;
//     position: relative;
//     top: 0;
//     left: 0;
//     z-index: 99;
//   }
// }
// .logo:hover {
//   .img-top {
//     display: inline;
//   }
//   .white-logo {
//     display: none;
//   }
// }
// table {
//   border-collapse: collapse;
// }


// .block-white {
//   background-color: white;
// }
// .block-orange {
//   color: $brand-orange;
// }
form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 5px;

    img {
      width: 18px;
      margin-top: 10px;
    }

    & .MuiTextField-root {
      width: 100%;
    }

    .formhelpertext {
      color: red;
      margin: -15px 0 0 20px;
      font-family: $font-family;
    }

    .textfield-label,
    .inputlabel,
    .outlinedinput-label,
    .select-label,
    .phoneinput,
    .MuiOutlinedInput-root,
    .react-tel-input .form-control {
      font-family: $font-family;
    }
  }
}

.MuiList-root,
.MuiButton-label {
  font-family: $font-family;
}

//all button colors in app will change
Button.MuiButton-contained {
  width: 100%;
  margin: 5px 0px;
  height: 50px;
  color: white;
  background-color: $brand-blue !important;
}

Button.MuiButton-contained:disabled {
  width: 100%;
  margin: 5px 0px;
  height: 50px;
  color: white;
  background-color: $lightgray-color !important;
}

.MuiGrid-spacing-xs-6>.MuiGrid-item {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $lightgray-color !important;
  position: relative;
  padding: 10px 20px;
}

.MuiTab-textColorPrimary.Mui-selected::after {
  width: 78%;
  content: "";
  position: absolute;
  bottom: 0px;
  top: 25%;
  border-bottom: 5px solid #989898;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 120px !important;
  }
}

// -------old css


.react-tel-input:nth-child(1) .form-control+div:before {
  content: "Phone number";
  position: absolute;
  top: -7px;
  left: 19px;
  display: block;
  background: white;
  padding: 0 5px;
  font-size: 13px;
  width: 100px;
}

.react-tel-input .form-control:nth-child(1) {
  width: 100%;
  height: 57px;
}

.MuiMenu-paper {
  max-height: calc(100% - 96px);
  -webkit-overflow-scrolling: touch;
  margin-top: 33px;
  // width: 130px;
}

.MuiAppBar-positionFixed {
  top: 56px;
  left: auto;
  right: 0;
  position: fixed;
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: secondary;
}

@media (min-width: 1100px) and (max-width: 3400px) {
  table {
    thead {

      td,
      th {
        font-weight: bold;
        font-family: $font-family;
        text-transform: uppercase;
        font-size: 0.875rem;
      }
    }

    td,
    th {
      padding: 0.75rem 0.55rem;
      vertical-align: top;
      font-family: $mono-space;
      border-top: 1px solid #dee2e6;
    }

    tbody td {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  table {
    thead {

      td,
      th {
        font-weight: bold;
        font-family: $font-family;
        text-transform: uppercase;
        font-size: 0.875rem;
      }
    }

    td,
    th {
      padding: 0.75rem 0.02rem;
      vertical-align: top;
      font-family: $mono-space;
      border-top: 1px solid #dee2e6;
    }

    tbody td {
      font-size: 16px;
    }
  }
}

a:hover {
  background-color: #ebeff2;
}

Button.MuiButton-contained:hover {
  background-color: #ebeff2;
}

Button.MuiButton-contained:disabled {
  background-color: #c8c9cb;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.body-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.footer-header {
  .MuiToolbar-gutters {
    padding-left: 0;
  }

  // .footer-setup {
  //   background-color: rgb(51, 51, 51);
  //   padding: 0.9em 8.2vw;
  //   color: silver;
  // }
  // .footer-head {
  //   text-align: center;
  //   p {
  //     font-size: 0.8rem;
  //     text-align: left;
  //     margin-bottom: 1rem;
  //     line-height: 1.2rem;
  //   }
  //   a {
  //     color: white;
  //     text-decoration: none;
  //   }
  //   a:hover {
  //     color: yellow;
  //     text-decoration: none;
  //     background-color: transparent;
  //   }
  // }
  // .footer-options {
  //   position: absolute;
  //   bottom: 0;
  //   height: 1.8rem;
  // }
  // @media only screen and (max-width: 600px) {
  //   .MuiToolbar-gutters {
  //     padding-left: 0;
  //   }
  // }
}

:focus {
  outline: 0 !important;
}

#alert-dialog-description {
  color: $brand-black;
}

#MuiDialogActions-root {
  justify-content: center;
}

.c3-chart-arc text {
  fill: white;
}

.MuiTextField-root {
  margin-bottom: 20px !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #ff0000 !important;
}

.App {
  text-align: center;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.menu-height {
  height: 64px;
}

.notmobile {
  display: block;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .mobile {
    display: block;
  }

  .notmobile {
    display: none !important;
  }

  .menu-height {
    height: 55px;
  }
}