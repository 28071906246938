@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

.client-agreement {
  margin-top: 3em;
  .scroll-area {
    overflow-y: scroll;
    height: 600px;
    // width: 100%;
    border: 3px solid #ddd;
    padding: 10px;

    p {
      text-align: justify;
    }
  }

  Button.Mui-disabled {
    background-color: $lightgray-color !important;
  }

  p {
    text-align: left;
  }
  .block-white {
    max-width: 1000px !important;
  }

  .stepsize {
    display: inline-flex;
  }
}
@media only screen and (max-width: 500px) {
  .client-agreement {
    .scroll-area {
      height: 400px;
    }
  }
}
