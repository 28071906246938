@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;

$brand-black: #000000;

//------------Steps info-------------------

.stepsinfo {
  text-align: center;
  margin-top: -1em !important;

  .stepinfo-titles {
    span {
      font-size: 2.5em;
      font-family: $font-family;
    }

    p {
      margin: 0;
      font-size: 23px;
      margin-top: -7px;
    }

    h2 {
      margin-top: 0;
      margin-top: 7px;
      margin-bottom: 48px;
    }

    h4 {
      font-size: 18px;
      font-weight: 100;
      width: 50%;
      margin: auto;
      margin-top: 20px !important;
      margin-bottom: 80px !important;
    }
  }

  .interview-process {
    display: flex;
    list-style-type: none;
    padding: 0;

    li {
      img {
        width: 65px;
      }

      .right-arrow {
        width: 40px !important;
        margin-top: 25px;
      }

      width: calc(100% / 3);
    }

    li:nth-child(2),
    li:nth-child(4) {
      width: 40px !important;
    }
  }

  .MuiButton-root {
    background-color: $brand-black;
    color: white;
    border-radius: 15px;
    width: 200px;
    height: 50px;
    margin-top: 25px;
  }

  .MuiButton-root:hover {
    background-color: $brand-black;
  }
}
@media only screen and (max-width: 700px) {
  .stepsinfo .interview-process {
    display: block;

    .right-arrow {
      transform: rotate(90deg);
      display: none;
    }

    li {
      width: auto;
    }

    li:nth-child(3),
    li:nth-child(5) {
      margin-top: 2em;
    }
  }
}
#seniorpolitical{
  .MuiTooltip-tooltip{
    max-height: 250px;
    overflow: auto;
  }
    /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
}