.outer-container {
  max-width: 1200px;
  padding-top: 1rem;
}
.card {
  border-radius: 10px !important;
  .new {
    flex-direction: column;
    position: relative;
    .image {
      background-blend-mode: darken;
    }
    p {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      left: 0;
      top: 0;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: 500;
      z-index: 999;
      margin: 0;
      padding: 8px 16px;
      padding-left: 5px !important;
    }
    .star {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      right: 0;
      top: 0;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: 500;
      z-index: 999;
      margin: 0;
      padding: 8px 16px;
      img {
        cursor: pointer;
      }
    }
  }
  .text-section {
    padding-top: 0;
    padding-bottom: 0 !important;

    .header {
      display: flex;
      flex-direction: column-reverse;
      .headline {
        font-size: 1.05rem;
        text-align: left;
        margin-top: 0.5rem;
        margin-bottom: 0rem;
      }
      .headlinelink {
        color: #1685d3;
        cursor: pointer;
      }
      .headlinelink:hover {
        background: transparent;
        text-decoration: underline;
      }
    }
    .upper-data-div {
      display: flex;
      gap: 0.2rem;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin: 0.3rem 0 0.5rem 0;
      @media screen and (max-width: 600px) {
        margin-top: 0.2rem;
      }
      .dates {
        display: flex;
        gap: 0.5rem;
        @media screen and (max-width: 1040px) {
          flex-direction: column;
          gap: 0.2rem;
        }
        @media screen and (max-width: 599px) {
          flex-direction: row;
          gap: 0.5rem;
          h4 {
            font-size: 15px;
          }
          p {
            font-size: 15px;
          }
        }
      }
      .lower-data-div {
        display: flex;
        gap: 0.35rem;
        font-size: 0.8125rem;
        h4 {
          margin: 0;
        }
        p {
          margin: 0;
        }
        @media screen and (max-width: 600px) {
          h4 {
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
        @media screen and (min-width: 600px) and (max-width: 960px) {
          h4 {
            font-size: 15px;
          }
          p {
            font-size: 15px;
          }
        }
      }
    }

    .text {
      opacity: 0.7;
      font-size: 16px;
      text-align: left;
      margin-top: 0rem;
      margin-bottom: 0.1rem;
      @media screen and (max-width: 600px) {
        margin-top: 0.4rem;
      }
      span {
        cursor: pointer;
        font-weight: 500;
        font-size: 1rem;
        line-height: 0;
      }
    }
    .MuiChip-root {
      height: 24px !important;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .MuiChip-label {
      padding: 4px !important;
    }
  }
}
.list-card {
  display: flex;
  border-radius: 10px !important;
  @media screen and (max-width: 600px) {
    display: block;
  }
  .new {
    width: 55%;
    background-size: cover;
    object-fit: cover;
    flex: 0 0 28%;
    @media screen and (min-width: 600px) and (max-width: 960px) {
      width: 90%;
      .image {
        height: 100%;
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.6rem;
    @media screen and (min-width: 600px) {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    @media screen and (max-width: 960px) {
      padding-bottom: 0.6rem !important;
    }
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      .headline {
        font-size: 1.2rem;
        margin-bottom: 0rem;
      }
      .headlinelink {
        color: #1685d3;
        cursor: pointer;
      }
      .headlinelink:hover {
        background: transparent;
        text-decoration: underline;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .upper-data-div {
      margin-bottom: 0.5rem;
      font-size: 15px;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 0.3rem;
      gap: 0.5rem;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        justify-content: flex-start;
        gap: 0.1rem;
        flex-direction: column !important;
      }
      @media screen and (min-width: 600px) and (max-width: 1040px) {
        .dates {
          flex-direction: row;
          gap: 0.5rem;
        }
      }
    }
    .text {
      margin: 0.3rem 0 0.1rem 0;
      width: 90% !important ;
      @media screen and (max-width: 600px) {
        margin-bottom: 0.5rem;
      }
    }
    .MuiChip-root {
      height: 24px !important;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    .MuiChip-label {
      padding: 4px !important;
    }
  }
}
.height {
  @media screen and (min-width: 1400px) {
    height: 100%;
    .image {
      height: 100%;
      max-height: 230px;
    }
  }
}
.result {
  text-align: center;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDialogContent-root {
  .card {
    border: none !important;
    border-radius: 4px !important;
  }
}
.pagination {
  max-width: 1200px;
  margin: 3rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0;

  @media screen and (max-width: 600px) {
    gap: 0;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
      padding: 0;
      margin: 0;
    }
  }

  .page-item {
    list-style: none;
  }

  .page-link {
    list-style: none;
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 400px) {
      width: 35px;
      height: 35px;
    }
  }

  .active {
    color: white;
    background: rgba(0, 0, 0, 0.4);
  }
}
.disabled {
  a {
    cursor: not-allowed !important;
    color: var(--ds-text-disabled, #a5adba) !important;
  }
}
