@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

//-------------Account staus----------------
.accountstatus {
  margin: auto;
  margin-top: 0em !important;

  .accountstatus-body {
    p {
      text-align: justify;
    }
  }

  table {
    width: 90%;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
  }

  tr td {
    text-align: center;
  }

  .status button {
    background: none;
    border: none;
    font-size: 14px;
    color: blue;
    font-family: $mono-space;
    background-color: yellow;
  }

  .status button:hover {
    background: none;
    text-decoration: underline;
  }

  .accountstatus-text {
    p {
      text-align: center !important;
    }
  }
}
.link_in_accountstatus button {
  background: none;
  border: none;
  font-family: $mono-space;
  text-decoration: underline;
  background-color: yellow;
  font-size: 14px;
  color: blue;
}

.link_in_accountstatus button:hover {
  background: none;
  text-decoration: underline;
}
.account-status-process {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;

  li {
    img {
      width: 65px;
    }

    .right-arrow {
      width: 40px !important;
      margin-top: 25px;
    }

    width: calc(100% / 3);
  }

  li:nth-child(2),
  li:nth-child(4) {
    width: 40px !important;
  }
}
