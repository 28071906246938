@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;

$lightgray-color: #989898;
$brand-black: #000000;
$brand-blue: #1685d3;

.fundingbasic {
  .MuiSelect-icon.Mui-disabled {
    display: none;
  }

  .select_account {
    color: black !important;
  }

  .delete_link_account {
    text-decoration: underline;
    background-color: #ffff00 !important;
    color: black;
  }
}

.fundingbasic.size {
  max-width: 500px !important;
  margin: auto;
}

.add-funds,
.transfer-processing,
.confirm-money-transfer,
.documents,
div[class^="step"],
.accountstatus {
  margin-top: 1em;
}

// ------------Linked accounts--------------
.link-accounts {
  .linked-accounts {
    table {
      width: 90%;
      font-size: 14px;
      margin-left: auto;
      margin-right: auto;

      tr td:nth-child(3) {
        color: $brand-blue;
      }

      .positive {
        color: $brand-blue !important;
      }

      .no {
        color: $lightgray-color;
      }
    }
  }

  .addicon {
    display: flex;
    align-items: center;
    color: $brand-blue;
  }
}

// ------------Confirm Money-transfer-------
.confirm-money-transfer {
  table {
    margin-left: auto;
    margin-right: auto;

    tr {
      height: 40px;
      text-align: left;

      td {
        font-family: $font-family;
        border-top: 0px solid black;
      }

      td:nth-child(2) {
        text-align: left;
        color: $lightgray-color;
      }
    }
  }

  .confirm {
    width: 80%;
  }
}

//-------------Add funds------------------
.add-funds {
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    width: 94px;
    margin: 1em auto;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid $lightgray-color;
  }

  .separator::before {
    margin-right: 0.25em;
  }

  .separator::after {
    margin-left: 0.25em;
  }

  .no-accounts {
    text-align: justify;
  }
}
.addfunds {
  .MuiTextField-root {
    margin-bottom: 5px !important;
  }
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #26a64a;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
#menu-select_block {
  .MuiMenu-list {
    .MuiButtonBase-root {
      display: flex !important;
      padding: 6px 30px !important;
    }
    .MuiListSubheader-root {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: #000000;
    }
  }
}
.cashmanager {
  display: flex;
  flex-direction: row;
}
.addfund-links {
  margin-top: 90px !important;
}
@media only screen and (max-width: 750px) {
  .cashmanager {
    flex-direction: column;
    .MuiGrid-item {
      max-width: 100% !important;
    }
    .cash-mobile {
      margin-top: -95px !important;
    }
    .addfund-links {
      margin-top: 0px !important;
    }
  }
}
.disabled-scroll {
  touch-action: none;
  -ms-touch-action: none;
}
.add-funds-table {
  table {
    width: 100% !important;
  }
}
