.Toastmessge {
  height: 80vh;
  width: 90%;
  margin: auto;
  position: relative;
  border: 3px solid green;

  .MuiContainer-root {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .toast-message-button {
    max-width: 500px;
  }
}