@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

// ----------- Portfolio--------------------
.portfolio {
  margin-top: 26px;
  display: flex;

  .aum {
    width: 27%;
    border-right: none;
    border-top: none;

    .boldClass .aum-table {
      padding-top: 2em;
      width: 90%;
      margin: 0 auto;
      text-align: left;

      tr {
        width: 90%;
        height: 40px;
        text-align: left;

        td {
          font-family: $font-family;
          font-size: 0.875rem;
          border-top: 0px solid $lightgray-color;
        }

        :nth-child(2) {
          font-weight: bold;
        }
      }
    }

    .portfolio-card1 {
      .card1 {
        .miniBar {
          height: 1rem;
          position: relative;
          width: 90%;
          margin: 80px auto 50px auto;

          .miniBarProgress1 {
            background-color: $brand-blue;
            height: 100%;
            position: absolute;
            top: 0rem;
            left: 0rem;
            width: 20%;
            margin-left: 12px;
          }

          .miniBarProgress2 {
            background-color: $brand-green;
            height: 100%;
            position: absolute;
            top: 0rem;
            left: 20%;
            width: 45%;
          }

          .miniBarProgress3 {
            background-color: $brand-orange;
            height: 100%;
            position: absolute;
            top: 0rem;
            left: 65%;
            width: 30%;
            margin-left: -12px;
          }

          .miniBarProgress4 {
            background-color: $brand-yellow;
            height: 100%;
            position: absolute;
            top: 0rem;
            left: 95%;
            width: 5%;
            margin-left: -12px;

            .barLabelBottom {
              text-align: right;
              margin-left: -24px;
            }
          }

          .barLabel {
            margin-top: -40px;
            font-size: 0.875rem;
            text-align: left;
          }

          .barLabelBottom {
            margin-top: 15px;
            font-size: 0.875rem;
            text-align: left;
          }
        }
      }

      .card2 {
        table {
          width: 90%;
          border-bottom: 0px;
          margin: 0 auto;

          tr td {
            text-align: center;
          }
        }
      }
    }
  }

  .holding-values {
    padding-top: 0em;
    width: 73%;
    border: 1px solid $lightgray-color;
    border-top: none;
    border-bottom: none;
    border-right: none !important;

    .portfolio-card2 {
      width: 100%;

      .MuiPaper-root {
        background-color: white;
        z-index: 10;
        padding-bottom: 6px;
      }

      .tabs-space {
        height: 1px;
        margin: -8px 0 0 0;
        padding: 0;
        background-color: $lightgray-color;
        z-index: 11;
      }

      .MuiTabs-flexContainer {
        background-color: white;
        padding: 0 1em 0 1em;
      }

      .by_sector {
        .by_sector_tables {
          display: flex;
          justify-content: space-around;
          font-size: 14px;
          text-align: left;

          .by_sector_body {

            tr td:nth-child(2),
            tr td:nth-child(3) {
              text-align: right;
            }

            tr:last-child {
              font-weight: bold;
              border-bottom: 1px solid lightgray;
            }
          }

          .highlight>td {
            font-weight: bold;
          }

          table {
            width: 300px;
            margin-left: auto;
            margin-right: auto;
          }

          .subtitle {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
          }

          h4,
          p {
            margin: 5px;
          }

          p {
            font-size: 12px;
            line-height: 12px;
          }

          .total_sector td {
            border-top: 1px solid #2f4f4f !important;
            border-bottom: 1px solid #2f4f4f !important;
          }

          .totalgross {
            td {
              width: 33.33%;
            }

            td:nth-child(2) {
              padding-right: 0px;
            }
          }
        }

        table {

          td:nth-child(2),
          td:nth-child(3) {
            text-align: right;
          }
        }
      }

      .by_holdings {
        .MuiBox-root {
          font-family: $font-family;
          font-size: 14px;
          // display: flex;
          justify-content: space-around;
        }

        .holding-positive {
          color: green;
          text-align: right;
        }

        .holding-negative {
          color: red;
          text-align: right;
        }
      }

      .MuiGrid-container {
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
      }
    }
  }

  .canvasjs-chart-credit {
    display: none;
  }
}

.by_sector_tables {
  tr {
    width: 90%;
    height: 40px;
    text-align: left;

    td {
      font-family: $font-family;
      font-size: 0.875rem;
    }
  }

  #bysector_table {
    td {
      width: 33.33%;
    }
  }
}

.card2 {
  tr {
    width: 90%;
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;

    td {
      font-family: $font-family;
      font-size: 0.875rem;
      border-top: none;
    }
  }
}

#sectorChart {
  margin-top: 10%;
}

.circle {
  height: 50px !important;
  width: 50px !important;
}

#circle-div {
  top: 50%;
  left: 50%;
  position: absolute;
}
.portfolio-class .portfolio .portfolio-cards .card .MuiButtonBase-root:hover {
  background-color: #ebeff2;
}

@media only screen and (max-width: 959px) {
  .portfolio {
    .aum {
      width: 40%;
    }

    .holding-values {
      width: 60%;

      .by_sector_tables {
        display: inline-table !important;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .portfolio {
    display: block;

    .aum {
      width: 100%;
    }

    .holding-values {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 820px) {
  .portfolio {
    .add-fund-mobile {
      padding-top: 50px;
    }
  }
}

//--------------Account Holdings ---------
.account-holdings {
  .table {
    width: 100%;
  }
}
.button-height{
  height: 32px !important;
}