@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

//------------Settings--------------------
.settings {
  font-family: $font-family;

  .MuiTypography-h6 {
    font-family: $font-family;
  }

  background-color: white;

  div[class^="step"] {
    margin-top: 0px !important;
  }

  .step-name,
  .br {
    display: none;
  }

  h3 {
    padding-bottom: 15px !important;
  }

  form {
    .MuiButton-root {
      margin: 0;
    }

    .dobFieldMarginBottom {
      margin-top: 0px !important;
    }
  }
}

.security-center {
  .MuiFormControl-root.firstchild {
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
.sucess_message {
  width: 20%;
  position: fixed;
  right: 0;
  top: 70px;
}

.setting_active {
  background-color: #000 !important;
  color: #fff !important;
}

.setting_deactive {
  background-color: #fff !important;
  color: #000 !important;
}

.setting_test_user {
  background-color: #ffff00 !important;
  color: #000 !important;
}

.setting_button_flip {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.trustedDevicedTableFontSize {
  width: 100%;
}

.trustedDevicedTableFontSize td {
  font-size: 14px;
}
p.success {
  color: green;
}
p.error {
  color: red;
}

.setting-notmobile {
  display: block !important;
}

.setting-mobile {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .setting-mobile {
    display: block !important;
  }

  .setting-notmobile {
    display: none !important;
  }
}