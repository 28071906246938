$brand-blue: #1685d3;

//-------------documents------------------
.documents {
  .MuiGrid-spacing-xs-6 {
    margin-top: 0px;
  }

  .MuiButton-root {
    color: $brand-blue  !important;
  }

  table {
    width: 90%;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;

    tr td {
      width: 25%;
    }
  }

  tr td:nth-child(4) {
    color: $brand-blue  !important;
  }

  .MuiGrid-spacing-xs-6>.MuiGrid-item {
    margin-bottom: 0em !important;
  }
}