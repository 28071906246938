@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

.benificiary_buttons {
  background-color: #fff !important;
  text-decoration: underline !important;
  color: blue !important;
  text-transform: none !important;
  min-width: 30px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.benificiary button {
  background: none;
  border: none;
  font-size: 14px;
  color: #fff;
  font-family: $mono-space;
  background-color: green;
  height: 35px;
  border-radius: 5px;
}

.benificiary button:hover {
  background: none;
  text-decoration: underline;
  color: blue;
}