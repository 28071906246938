@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

.stock-memo{
  font-family: $font-family;
  font-weight: 500;
  margin: 0px !important;
  .MuiCardHeader-title{
    font-size: 18px;
  }
  .MuiCardHeader-subheader{
    color: #fff;
    font-size: 13px;
  }
  .MuiDialogContent-root::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  .title{
    padding: 10px 2px 0px 5px;
    font-size: 11px;
    color: #fff;
  }
  .title_value{
    font-size: 16px;
    padding: 0px 2px 0px 5px;
    color: #fff;
  }
  .buy{
    background: #ff832e;
    color: #fff;
    padding: 0px;
  }
  .sell{
    background: #1787d0;
    color: #fff;
    padding: 0px;
  }
}