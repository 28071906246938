.blocks {
  text-align: left;

  img {
    width: 100%;
  }

  .head {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: #1685d3;
  }

  .performance_title {
    padding-left: 10px !important;
  }

  .overview {
    margin: 8px;
    background: radial-gradient(
      ellipse at 100% 100%,
      rgba(255, 211, 0, 0.4) 0%,
      rgba(255, 133, 33, 0.4) 100%
    );
    width: 100%;
    border-radius: 16px;
  }

  .block-details {
    margin: 8px;
    background: radial-gradient(
      ellipse at 100% 100%,
      rgba(0, 163, 255, 0.4) 0%,
      rgba(38, 166, 74, 0.4) 100%
    );
    width: 100%;
    border-radius: 16px;
  }

  .block-strategy {
    margin: 8px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      linear-gradient(0deg, #00a3ff, #00a3ff), #26a64a;
    width: 100%;
    border-radius: 16px;
  }

  .holdings {
    margin: 8px;
    border-radius: 8px;
    background: linear-gradient(0deg, #ececee, #ececee), #26a64a;
    width: 100%;
    border-radius: 16px;
    min-height: 270px;
  }

  .etd {
    margin: 8px;
    border-radius: 8px;
    background: linear-gradient(0deg, #ececee, #ececee), #26a64a;
    width: 100%;
    border-radius: 16px;
    min-height: 150px;
  }

  .cumulative {
    margin: 8px;
    background: linear-gradient(0deg, #ffedde, #ffedde),
      linear-gradient(0deg, #ececee, #ececee), #26a64a;
    width: 100%;
    border-radius: 16px;
  }

  .holdings-table {
    .head {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
    }

    margin: 8px;
    background: linear-gradient(0deg, #1685d3, #1685d3),
      linear-gradient(0deg, #ececee, #ececee), #26a64a;
    width: 100%;
    border-radius: 16px !important;
    padding-bottom: 30px;
  }

  .MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  .MuiGrid-spacing-xs-6 > .MuiGrid-item:last-child {
    padding-bottom: 24px !important;
  }

  .pt-24 {
    padding-top: 24px !important;
  }

  .recharts-wrapper {
    margin: auto;
  }

  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-grid {
    display: none;
  }

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 0px !important;
  }

  .MuiToggleButtonGroup-grouped {
    padding: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 12px !important;
    color: #000 !important;
    width: 65px !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: white !important;
    color: #000000 !important;
    font-size: small;
  }

  .MuiToggleButton-root {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  #block-wrap {
    max-width: 800px;
    position: relative;
    margin: auto;
    z-index: 9;

    .legend {
      // max-width: 800px;
      right: 0px;
      top: -30px;
      position: absolute;
      text-align: center;
      margin: 0 auto;
      display: flex;
      height: 30px;

      label {
        padding: 5px;
      }

      .MuiToggleButton-root.Mui-selected {
        font-weight: bold;
      }

      .MuiButtonBase-root {
        padding: 11px !important;
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-radius: 4px !important;
      }

      .MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      .MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: -1px !important;
        border-left: 1px solid transparent !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }
  .holdinglist {
    thead,
    tbody {
      th:nth-child(1),
      td:nth-child(1) {
        line-height: 1.5em;
        margin: 0;
        padding: 10px;
        width: 4% !important;
      }

      th,
      td {
        line-height: 1.5em;
        margin: 0;
        padding: 10px;
        width: 1% !important;
      }

      th:last-child,
      td:last-child {
        width: 10% !important;
        //text-align: left !important;
      }
    }

    .MuiTableCell-root {
      border-left: 1px solid #1685d3;
      border-bottom: 1px solid #fff;
    }

    .MuiTableCell-head {
      font-weight: 800 !important;
      font-size: 14px !important;
    }

    .MuiTableCell-head:not(:nth-child(1)):not(:nth-child(7)) {
      div {
        justify-content: center;
      }
    }

    .MuiTableCell-root:not(:nth-child(1)):not(:nth-child(7)) {
      text-align: center;
    }

    .MuiTableRow-root {
      background-color: #a2ceed !important;
    }

    .MuiTableRow-root:nth-child(even) {
      background-color: #85bfe8 !important;
    }
  }
  .block_performance {
    .MuiTableRow-head {
      background: #1685d3;
      color: #fff;

      th {
        color: #fff;
        padding: 5px !important;
      }
    }

    .MuiTableCell-root {
      border-left: 1px solid rgba(224, 224, 224, 1);
    }
  }
}

.banner-button {
  position: relative;
  // bottom: 50px;
  justify-content: end;
  width: 100%;
  z-index: 999;

  // top: 240px;
  Button.MuiButton-contained {
    width: 100%;
    margin: 5px 5px;
    height: 40px;
    color: white;
    width: 150px !important;
    border-radius: 16px;
    float: right;
  }

  .invest {
    background-color: #26a64a !important;
  }

  .share {
    background-color: #fff !important;
    border: 2px solid #26a64a;
    color: #26a64a !important;
  }
}

.banner-title {
  font-size: 24px !important;
  color: #fff !important;
}

.carousel .control-dots {
  bottom: 0;
  width: unset !important;
  top: 80px;
  left: 50px;
}

.carousel .slide .legend {
  top: 30px !important;
  left: 50px !important;
  bottom: auto !important;
  font-size: 36px !important;
  opacity: unset !important;
  background: unset !important;
  width: unset !important;
  margin-left: unset !important;
  height: auto;
}

.carousel .control-dots .dot {
  opacity: unset !important;
  border: 1.5px solid #fff;
  background: unset !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #fff !important;
}

.carousel .slide img {
  height: 150px;
}

.carousel.carousel-slider .control-arrow {
  top: 50px !important;
  bottom: unset !important;
  // background: #26a64a !important;
  opacity: 1 !important;
}

.carousel .control-prev.control-arrow {
  left: 35px !important;
}

.carousel .control-next.control-arrow {
  left: 300px !important;
  right: unset !important;
}

@media (min-width: 640px) {
  .carousel .slide .legend {
    background: unset !important;
    font-size: 36px !important;
    opacity: unset !important;
    bottom: 20px !important;
    width: 100% !important;
    position: absolute;
    max-width: 1200px;
    text-align: start;
    left: -30px !important;
    right: 0;
    margin: auto !important;
    padding-left: 70px;
    padding-right: 70px;
    top: auto !important;
    height: auto;
  }

  .carousel .control-dots {
    bottom: 10px;
    width: 100% !important;
    height: 20px;
    position: absolute;
    max-width: 1200px;
    text-align: start;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
    top: auto !important;
  }

  .carousel .control-prev.control-arrow {
    bottom: 32px !important;
    width: 100%;
    position: absolute;
    max-width: 1200px;
    text-align: start;
    left: 0 !important;
    right: 0;
    margin: auto;
    padding-left: 25px;
    padding-right: 42px;
    top: auto !important;
  }

  .carousel .control-next.control-arrow {
    bottom: 32px !important;
    width: 100%;
    position: absolute;
    max-width: 1200px;
    text-align: start;
    left: 435px !important;
    right: 0 !important;
    margin: auto;
    padding-left: 42px;
    padding-right: 42px;
    top: auto !important;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none !important;
  }
  .backtolist {
    margin-bottom: -60px;
    margin-left: 70px;
  }

  .carousel-navigators {
    margin-top: -30px !important;
  }
}

.first-toggle-button {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.last-toggle-button {
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.recharts-tooltip-wrapper .custom-tooltip {
  width: 100px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: #fff; //hsla(0, 0%, 100%, 0.8);
  padding: 10px;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
  margin: 0;
  color: #000;
  font-weight: 500;
}
.carousel-navigators .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  background: #fff;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel-navigators .control-dots .dot {
  opacity: unset !important;
  border: 1.5px solid #fff;
  background: unset !important;
}
.carousel-navigators .control-dots .dot.selected,
.carousel-navigators .control-dots .dot:hover {
  background: #fff !important;
}
.MuiMenu-list {
  .MuiButtonBase-root {
    display: flex !important;
    padding: 6px 16px !important;
  }
}
.backtolist {
  position: relative;
  width: 100%;
  z-index: 999;
  text-align: left;
  margin-bottom: -60px;
  margin-left: 70px;
  top: 20px;
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }
  svg {
    color: #fff;
    font-size: 1rem;
  }
}
.carousel-navigators {
  position: relative;
  width: 100%;
  z-index: 999;
  text-align: left;
  margin-bottom: -35px;
  margin-left: 37px;
  display: flex;
  margin-top: -75px;
}
.img-star:hover {
  background: transparent !important;
  text-decoration: underline;
}

#block-cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  height: 220px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#block-cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #26a64a;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
.topbar_menu {
  .MuiButtonBase-root {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: flex-start;
    display: flex;
  }
}
.holdinglist-footer {
  text-align: center !important;
  font-size: 0.9rem !important;
  border: 1px solid rgba(224, 224, 224, 1);
  border-top: 0px !important;
  font-weight: 600 !important;
  font-style: italic !important;
}
