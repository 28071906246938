.blocks-table {
  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

  .MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0px 15px;
    border-collapse: separate;
  }

  table td {
    padding-bottom: 25px !important;
    padding-top: 25px !important;
    text-align: center;
  }

  th:first-child {
    div {
      justify-content: left;
    }
    width: calc((100% - 0px) / 2) !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding-left: 120px !important;
    border-left: 1px solid #039be5;
  }
  th:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-right: 1px solid #039be5;
    width: calc((100% - 0px) / 2) !important;
  }

  th {
    width: calc((100% - 0px) / 8) !important;

    div {
      justify-content: center;
    }
    border-top: 1px solid #039be5;
    border-bottom: 1px solid #039be5;
  }
  .MuiTableRow-root {
    td {
      border-top: 1px solid #039be5;
      padding-bottom: 25px !important;
      padding-top: 25px !important;
      border-bottom: 1px solid #039be5;
    }
    td:first-child {
      border-left: 1px solid #039be5;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    td:last-child {
      border-right: 1px solid #039be5;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .img-block {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
  .MuiButton-textPrimary {
    font-weight: bold;
    margin-top: 10px;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #039be5;
  }

  .MuiTableSortLabel-root {
    font-size: 1rem;
    font-weight: bold;
  }
  .image-grid {
    width: 105px !important;
    align-self: stretch;
    justify-content: flex-end;
  }
  .image-div {
    text-align: end;
    margin-top: 12px;
  }
}