.outer-container {
  max-width: 1200px;
  z-index: 999;
  position: relative;

  .main-outer-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;

    @media screen and (max-width: 420px) {
      flex-direction: column;
    }

    .search-bar-outer-paper {
      padding: 2px 4px;
      display: flex;
      align-items: center;
      width: 250px;
      height: 55;

      .search-icon {
        padding: 10px 10px 10px 10px;
        margin: auto auto auto 10px;
        color: black;

        @media screen and (max-width: 600px) {
          padding: 5px;
          margin-left: 0;
        }
      }

      .search-input-field {
        margin-left: 1px;
        flex: 1;
        font-size: 14;
        border: none;
        border-radius: 8px;
        color: black !important;
        padding: 5px 0 5px;
      }
    }

    .sort-options {
      height: 50px !important;
      width: 150px;
      background: white !important;

      @media screen and (max-width: 550px) {
        width: 80px;
      }
    }

    .Mui-focused {
      outline: none !important;
    }

    .list-grid-icons-div {
      display: flex;
      gap: 0.7rem;
      width: 90px;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      height: 50px;
      border-radius: 3px !important;
      background: white;
      justify-content: center;
      align-items: center;
      .btn {
        border-radius: 5px !important;
      }

      .active {
        background-color: rgba(128, 128, 128, 0.3);
        border-radius: 5px !important;
      }
    }
    form {
      margin-right: 0 !important;
    }
  }

  .filter-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;
    margin-top: -2.3rem;
    margin-right: 4rem;

    @media screen and (max-width: 380px) {
      margin-top: -6.9rem;
      margin-right: 6rem;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      width: 130px;
      height: 130px;
      z-index: 999;
      padding: 0;
      li {
        list-style: none;
        cursor: pointer;
      }
    }
  }
}

.icon-buttons {
  width: 50px;
  height: 50px;
  border-radius: 3px !important;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}