.blocks-light {
  text-align: left;

  img {
    width: 100%;
  }

  .head {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: #1685d3;
  }

  .performance_title {
    padding-left: 10px !important;
  }

  .overview {
    margin: 8px;
    width: 100%;
  }

  .block-details {
    margin: 8px;
    width: 100%;
  }

  .block-strategy {
    margin: 8px;
    width: 100%;
  }

  .holdings {
    margin: 8px;
    border-radius: 8px;
    width: 100%;
    min-height: 270px;
  }

  .etd {
    margin: 8px;
    border-radius: 8px;
    width: 100%;
    min-height: 150px;
  }

  .cumulative {
    margin: 8px;
    width: 100%;
  }

  .holdings-table {
    .head {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      color: #1685d3;
    }

    margin: 8px;
    width: 100%;
    padding-bottom: 30px;
  }

  .MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  .MuiGrid-spacing-xs-6>.MuiGrid-item:last-child {
    padding-bottom: 24px !important;
  }

  .pt-24 {
    padding-top: 24px !important;
  }

  .recharts-wrapper {
    margin: auto;
  }

  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-grid {
    display: none;
  }

  .MuiGrid-spacing-xs-1>.MuiGrid-item {
    padding: 0px !important;
  }

  #block-wrap {
    max-width: 800px;
    position: relative;
    margin: auto;
    z-index: 9;

    .legend {
      // max-width: 800px;
      right: 0px;
      top: -30px;
      position: absolute;
      text-align: center;
      margin: 0 auto;
      display: flex;
      height: 40px;

      label {
        padding: 5px;
      }

      .MuiToggleButton-root.Mui-selected {
        font-weight: bold;
      }

      .MuiButtonBase-root {
        padding: 11px !important;
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-radius: 4px !important;
      }

      .MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      .MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: -1px !important;
        border-left: 1px solid transparent !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }

  .holdinglist {
    thead,
    tbody {

      th:nth-child(1),
      td:nth-child(1) {
        line-height: 1.5em;
        margin: 0;
        padding: 10px;
        width: 4% !important;
      }

      th,
      td {
        line-height: 1.5em;
        margin: 0;
        padding: 10px;
        width: 1% !important;
      }

      th:last-child,
      td:last-child {
        width: 10% !important;
        //text-align: left !important;
      }
    }
    .MuiPaper-rounded {
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.16);
      border-top: none;
      box-shadow: none !important;
      border-radius:0px !important;
    }
    .MuiTableCell-head {
      font-weight: 800 !important;
      font-size: 14px !important;
    }

    .MuiTableCell-head:not(:nth-child(1)):not(:nth-child(7)) {
      div {
        justify-content: center;
      }
    }

    .MuiTableCell-root:not(:nth-child(1)):not(:nth-child(7)) {
      text-align: center;
    }
    .MuiTableRow-root {
      background-color: #fff !important;
    }

    .MuiTableRow-root:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }

  .MuiPaper-elevation {
    box-shadow: none !important;
  }
  .border-right{
    border-right: 1px solid;
    border-color: rgba(0, 0, 0, 0.16);
  }
  .border-top{
    border-top: 1px solid;
    border-color: rgba(0, 0, 0, 0.16);
  }
  .MuiToggleButton-root.Mui-selected {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiToggleButton-root{
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiTableCell-head{
    color: rgba(0, 0, 0, 0.8) !important;
  }
  .block_performance {
    .MuiTableRow-head {
      background: #fff;
      th {
        padding: 5px !important;
        font-weight: bold !important;
      }
    }
    .MuiTableCell-root {
      border-left: 1px solid rgba(224, 224, 224, 1);
    }
    .MuiTableCell-root:nth-child(4) {
      border-right: 1px solid rgba(224, 224, 224, 1);
    }
  }
}