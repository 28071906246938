.MuiAccordionSummary-root {
  // background-color:#E29E4B !important;
  height: 14px;
}

.MuiAccordion-root.Mui-expanded {
  margin-top: 0px !important;
}

.react-multi-carousel-track {
  padding-left: 5px !important;
}

.blocklegend {
  // max-width: 800px;
  top: 20px;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  height: 30px;
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

.block-header-legend {
  border-radius: 5px;
  width: 100%;
  height: 150px;
}

.slick-prev {
  left: 10px !important;
  z-index: 1;
}

.slick-next {
  right: 10px !important;
  z-index: 1;
}

.slick-list {
  margin-left: 40px !important;
  margin-right: 40px !important;
  margin-bottom: 5px !important;

  .cardItem {
    background: #fff;
    line-height: 100px;
    margin: 5px;
    padding: 2%;
    position: relative;
    text-align: center;
    height: 150px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 24px;
    border: 1px solid;
    border-radius: 4px;
  }

  .MuiCardContent-root {
    padding: 24px !important;
  }
}

.slick-next:before,
.slick-prev:before {
  color: #000 !important;
}

.slick-track {
  margin: auto !important;
  width: max-content !important;
}

.slick-prev,
.slick-next {
  top: 50% !important;
}

.block-close {
  position: absolute !important;
  right: 0;
}

.vl {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 1.0);
  border-bottom-width: 0;
  margin-bottom: 8px;
  border-right-width: thin;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  height: 120px;
  margin-top: 90px;
  width: 0% !important;
  margin-left: 20px;
}

.block_tray_header {
  background-color: transparent;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 0px;
}

.block_tray {
  display: flex;

  .cardItem {
    background: #fff;
    line-height: 100px;
    margin: 5px;
    padding: 2%;
    position: relative;
    text-align: center;
    height: 150px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 24px;
    border: 1px solid;
    border-radius: 4px;
    margin-left: 0.7rem;
  }

  .MuiCardContent-root {
    padding: 24px !important;
  }
}

.variable-width {
  max-width: 100vw;
  overflow: hidden;
}