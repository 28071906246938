.step1 form .MuiFormControl-root {
  margin-bottom: 8px;
}

.step1 .MuiTextField-root {
  margin-bottom: 0px !important;
}

.step1 .MuiTypography-paragraph {
  margin-bottom: 8px !important;
}
.non-citizen {
  margin: auto !important;
  margin-left: 15px !important;
  margin-top: 13px !important;
}
@media screen and (max-width: 600px) {
  .non-citizen {
    text-align: left;
    margin-left: 5px !important;
    margin-top: 13px !important;
  }
}
