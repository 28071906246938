@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family: "Roboto", sans-serif;
$mono-space: "Roboto Mono", monospace;

$lightgray-color: #989898;

$brand-orange: #ff8521;
$brand-yellow: #ffd300;
$brand-green: #26a64a;
$brand-black: #000000;
$brand-blue: #1685d3;

//------------Email Notifications---------
.email-notifications {
  .MuiFormGroup-root {
    display: block;
  }

  table {
    width: 90%;
    margin: 1em auto 0 auto;

    tr {
      height: 40px;
      text-align: left;

      td {
        padding: 0;
        font-size: 0.875rem;
        font-family: $font-family;
        border-top: 0px solid black;
        vertical-align: middle;
      }

      td:nth-child(2) {
        text-align: left;
        padding-left: 1em;
        color: $lightgray-color;
      }
    }
  }

  form {
    text-align: center;
  }

  .Form-control-label {
    font-size: 0.875rem;
    color: $brand-black;
  }
}